import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import Main from '../components/Accounts/Main'
import WhyChoose from '../components/Accounts/WhyChoose'
import Overview from '../components/Accounts/Overview'
import Swift from '../components/Accounts/Swift'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <Main />
      <LazyLoad><WhyChoose /></LazyLoad>
      <LazyLoad><Overview /></LazyLoad>
      <LazyLoad><Swift /></LazyLoad>
    </div>
  );
}

export default Home;