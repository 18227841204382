import React from 'react';

import { useTranslation } from 'react-i18next';

function PAMM() {

    const { t } = useTranslation();

  return (
<div className='bg-[#EDF2EF] z-10 relative'>
    <div className='max-w-[1280px] mx-auto pt-[120px] xl:pt-[160px] z-10 relative flex flex-col items-center'>
        <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] font-medium ml-[10px] xl:ml-0 mr-[10px]'>{t('pamm1')}</h1>
        <p className='textcolor max-w-[515px] roboto text-center mt-[20px]'>{t('pamm2')}</p>
        <div className='flex flex-col xl:flex-row mt-[30px] xl:mt-[60px] mx-[20px] xl:mx-0'>
            <div>
                <div className='flex'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm3')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm4')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm5')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm6')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm7')}</p>
                </div>
            </div>
            <div className='xl:ml-[20px]'>
                <div className='flex'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm8')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm9')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm10')}</p>
                </div>
                <div className='flex mt-[20px]'>
                    <div className='mt-[5px]'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                        <g clip-path="url(#clip0_253_422)">
                        <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <p className='textcolor roboto text-[16px] ml-[10px] max-w-[465px] font-light'>{t('pamm11')}</p>
                </div>
            </div>
        </div>
    </div>
</div>

  );
}

export default PAMM;