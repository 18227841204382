import React, { useEffect } from 'react';

import Main from '../components/Help/Main'

function Help() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='pt-[60px] bg-[#EDF2EF] relative overflow-hidden'>
      <Main />
    </div>
  );
}

export default Help;