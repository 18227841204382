import React, { useState } from 'react';

import button from '../../img/Help/button.png'

import { useTranslation } from 'react-i18next';

function Main() {

  const { t } = useTranslation();

  const [openIndex, setOpenIndex] = useState(null);

  const toggleText = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      question: 'help3',
      answer: 'help4'
    },
    {
      question: 'help5',
      answer: 'help6'
    },
    {
      question: 'help7',
      answer: 'help8'
    },
    {
        question: 'help9',
        answer: 'help10'
      },
      {
        question: 'help11',
        answer: 'help12'
      },
      {
        question: 'help13',
        answer: 'help14'
      },
      {
        question: 'help15',
        answer: 'help16'
      },
      {
        question: 'help17',
        answer: 'help18'
      },
      {
        question: 'help19',
        answer: 'help20'
      },
      {
        question: 'help21',
        answer: 'help22'
      },
  ];

  return (
    <div className='bg-[#EDF2EF]'>
        <div className='max-w-[1280px] mx-auto flex flex-col pt-[40px] xl:pt-[80px]'>
            <h1 className='mx-auto manrope text-[32px] xl:text-[48px] font-semibold text-[#493843]'>{t('help1')}</h1>
            <p className='pb-[60px] xl:mx-auto max-w-[552px] textcolor roboto text-[14px] xl:text-[16px] mx-[20px] text-center mt-[20px]'>{t('help2')}</p>
            {faqData.map((faq, index) => (
              <div key={index}>
                <div className='flex justify-between mt-[20px] mx-[40px]'>
                    <div>
                        <p className='manrope text-[14px] xl:text-[16px] text-[#493843] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                        {openIndex === index && (
                          <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[1280px]'>{t(faq.answer)}</p>
                        )}
                    </div>
                    <div className='cursor-pointer' onClick={() => toggleText(index)}>
                        <img src={button} alt="" className={`rotated ${openIndex === index ? 'rotated-open' : ''}`}/>
                    </div>
                </div>
                <div className='h-[1px] w-auto line mt-[20px]'></div>
              </div>
            ))}
        </div>
    </div>
  );
}

export default Main;
