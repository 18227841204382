import React from 'react';
import { useTranslation } from 'react-i18next';

import block1 from '../../img/Home/block1.png';
import block2 from '../../img/Home/block2.png';
import block2adapt from '../../img/Home/block2adapt.png';
import block3 from '../../img/Home/block3.png';
import block4 from '../../img/Home/block4.png';
import block5 from '../../img/Home/block5.png';

import Fade from 'react-reveal/Fade';

function PopularTrade() {
  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px]'>
      <div className='container mx-auto'>
        <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] ml-[10px] xl:ml-0 mr-[10px] xl:text-center font-medium'>
          {t('popular_title')}
        </h1>
        <div className='flex flex-wrap gap-[50px] mt-[60px] justify-center'>
          <Fade left duration={1000}>
            <div className='blockpopulartrade bg-[#DEE2E1] w-[392px] mr-[10px] ml-[10px] xl:mr-0 xl:ml-0 h-[250px] rounded-[20px] relative overflow-hidden'>
              <h1 className='text-[#493843] manrope text-[20px] md:text-[28px] font-bold mt-[30px] xl:mt-[40px] xl:ml-[40px] ml-[20px]'>
                {t('forex')}
              </h1>
              <img src={block1} alt="" className='absolute bottom-0 right-0' />
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={500}>
            <div className='blockpopulartrade bg-[#DEE2E1] mr-[10px] ml-[10px] xl:mr-0 xl:ml-0 w-[392px] h-[250px] rounded-[20px] flex relative overflow-hidden'>
              <h1 className='text-[#493843] manrope text-[20px] md:text-[28px] font-bold mt-[30px] xl:mt-[40px] xl:ml-[40px] ml-[20px] z-10'>
                {t('indices')}
              </h1>
              <img src={block5} alt="" className='absolute pl-[73px]' />
            </div>
          </Fade>
          <Fade right duration={1000} delay={800}>
            <div className='blockpopulartrade bg-[#DEE2E1] w-[392px] mr-[10px] ml-[10px] xl:mr-0 xl:ml-0 h-[250px] rounded-[20px] flex relative overflow-hidden'>
              <h1 className='text-[#493843] manrope text-[20px] md:text-[28px] font-bold mt-[30px] xl:mt-[40px] xl:ml-[40px] ml-[20px]'>
                {t('commodities')}
              </h1>
              <img src={block4} alt="" className='absolute bottom-0' />
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={1100}>
            <div className='blockpopulartrade bg-[#DEE2E1] w-[392px] mr-[10px] ml-[10px] xl:mr-0 xl:ml-0 xl:w-[620px] h-[250px] rounded-[20px] relative overflow-hidden'>
              <h1 className='text-[#493843] manrope text-[20px] md:text-[28px] font-bold mt-[30px] xl:mt-[40px] xl:ml-[40px] ml-[20px] z-10 relative'>
                {t('cryptocurrencies')}
              </h1>
              <img src={block3} alt="" className='absolute bottom-0 right-0' />
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={1400}>
            <div className='blockpopulartrade bg-[#DEE2E1] w-[392px] mr-[10px] ml-[10px] xl:mr-0 xl:ml-0 xl:w-[620px] h-[250px] rounded-[20px] overflow-hidden relative'>
              <h1 className='text-[#493843] manrope text-[20px] md:text-[28px] font-bold mt-[30px] xl:mt-[40px] xl:ml-[40px] ml-[20px] z-10 relative'>
                {t('precious_metals')}
              </h1>
              <img src={block2} alt="" className='absolute bottom-0 right-0 hidden xl:block' />
              <img src={block2adapt} alt="" className='absolute bottom-0 right-0 block xl:hidden' />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default PopularTrade;
