import React from 'react';

import { useTranslation } from 'react-i18next';

import earth from '../../img/Company/earth.png';
import vector2 from '../../img/Services/vectoryellow.png';
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

function Main() {

    const { t } = useTranslation();

  return (
    <div className='bg-[#DEE2E1] h-[858px] md:h-[980px] xl:h-[790px] flex'>
        <div className='mx-auto hidden xl:flex'>
            <div className='mr-[150px] mt-[100px] z-10'>
                <Fade left duration={500} delay={500}><h1 className='max-w-[700px] text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('company1')}</h1></Fade>
                <Fade left duration={500} delay={700}><p className='textcolor max-w-[552px] mt-[20px]'>{t('company2')}</p></Fade>
            </div>
            <div>
                <Zoom bottom duration={1000}><img src={earth} alt="" className='relative z-10 mt-[80px] ml-[90px]'/></Zoom>
                <Fade left duration={2000}><img src={vector2} alt="" className='absolute top-[60px]'/></Fade>
            </div>
        </div>
        <div className='flex flex-col xl:hidden mx-auto'>
            <div className='mt-[50px] ml-[20px] z-10 mx-[20px]'>
            <Fade left duration={500} delay={500}><h1 className='max-w-[700px] text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('company3')}</h1></Fade>
                <Fade left duration={500} delay={700}><p className='textcolor max-w-[552px] mt-[20px]'>{t('company4')}</p></Fade>
            </div>
            <div>
                <Zoom bottom duration={1000}><img src={earth} alt="" className='mt-[100px] relative z-10 w-[292.603px] h-[297.809px] md:w-[381.757px] md:h-[392.074px] mx-auto'/></Zoom>
                <Fade left duration={2000}><img src={vector2} alt="" className='absolute top-[440px] md:top-[240px]'/></Fade>
            </div>
        </div>
    </div>
  );
}

export default Main;