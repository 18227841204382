import React from 'react';

import { useTranslation } from 'react-i18next';

import rocket from '../../img/Accounts/rocket.png'

import Fade from 'react-reveal/Fade'

function WhyChoose() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px]'>
        <div className='max-w-[1280px] mx-auto'>
          <h1 className='max-w-[335px] xl:max-w-[473px] mx-auto text-center text-[#493843] manrope text-[32px] xl:text-[40px] font-medium'>{t('whychoose1')}</h1>
          <div className='flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]'>
            <div className='mx-[20px] xl:mx-0'>

              <Fade delay={100} left><div className='xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose2')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose3')}</p>
              </div></Fade>

              <Fade delay={300} left><div className='mt-[20px] xl:mt-[40px] xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose4')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose5')}</p>
              </div></Fade>

              <Fade delay={300} left><div className='mt-[20px] xl:mt-[40px] xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose6')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose7')}</p>
              </div></Fade>

            </div>
            <div className='mx-[20px] xl:mx-0 mt-[20px] xl:mt-0'>

              <Fade delay={100} right><div className='xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose8')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose9')}</p>
              </div></Fade>

              <Fade delay={300} right><div className='mt-[20px] xl:mt-[40px] xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose10')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose11')}</p>
              </div></Fade>

              <Fade delay={300} right><div className='mt-[20px] xl:mt-[40px] xl:w-[393px] h-[180px] xl:h-[162px] bg-[#DEE2E1] rounded-[20px]'>
                <div className='flex items-center mx-[40px] pt-[36px]'>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#F5B700"/>
                    <g clip-path="url(#clip0_253_422)">
                      <path d="M6.12702 10.0958L3.83336 7.73894C3.82014 7.72534 3.80964 7.70918 3.80248 7.69138C3.79531 7.67359 3.79163 7.6545 3.79163 7.63522C3.79163 7.61595 3.79531 7.59686 3.80248 7.57906C3.80964 7.56126 3.82014 7.5451 3.83336 7.53151L4.41241 6.9325C4.46774 6.87532 4.55717 6.87532 4.6125 6.9325L6.12252 8.48354C6.17785 8.54072 6.26792 8.54005 6.32325 8.48288L9.65984 5.00184C9.71517 4.944 9.80524 4.944 9.86121 5.00117L10.4409 5.60018C10.4962 5.65736 10.4962 5.74977 10.4415 5.80694L6.80643 9.59713L6.80708 9.5978L6.32776 10.0958C6.27243 10.1529 6.18235 10.1529 6.12702 10.0958Z" fill="#493843"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_253_422">
                        <rect width="8.23529" height="8.5098" fill="white" transform="translate(3.01953 3.29346)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <h1 className='manrope text-[20px] text-[#493843] font-medium ml-[12px]'>{t('whychoose12')}</h1>
                </div>
                <p className='textcolor mx-[40px] mt-[12px] roboto text-[16px] font-light'>{t('whychoose13')}</p>
              </div></Fade>
            </div>
          </div>
          <Fade duration={1500} delay={1000} bottom><img src={rocket} alt="" className='mx-auto mt-[40px] xl:mt-[-430px]'/></Fade>
        </div>
    </div>
  );
}

export default WhyChoose;