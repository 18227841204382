import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import frame from '../../img/Home/frame.png';
import frameadapt from '../../img/Home/frameadapt.png';
import best1 from '../../img/Home/best1.png';
import best2 from '../../img/Home/best2.png';
import best3 from '../../img/Home/best3.png';
import best4 from '../../img/Home/best4.png';
import button from '../../img/Home/button.svg';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import "swiper/css";

function BrandBuilt() {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});

  return (
    <>
      <div className='bg-[#EDF2EF] pt-[120px] xl:pt-[160px] justify-center items-center relative h-[712px] xl:flex hidden'>
        <img src={frame} alt="" className='block mx-auto w-full h-full object-cover' />
        <div className='absolute left-0 flex justify-center items-center w-full h-full'>
          <div className='max-w-[1280px] mx-auto'>
            <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] ml-[10px] xl:ml-0 mr-[10px] text-center font-medium'>
              {t('brand_built_title')}
            </h1>
            <p className='textcolor text-center roboto font-light text-[14px] md:text-[16px] mx-auto mt-[20px] ml-[10px] mr-[10px]'>
              {t('brand_built_description')}
            </p>
            <div className='flex gap-[40px] mt-[40px]'>
              <div className='blockcolor w-[269px] h-[327px]'>
                <img src={best1} alt="" className='mx-auto' />
                <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                  {t('award1')}
                </p>
              </div>
              <div className='blockcolor w-[269px] h-[327px]'>
                <img src={best2} alt="" className='mx-auto' />
                <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                  {t('award2')}
                </p>
              </div>
              <div className='blockcolor w-[269px] h-[327px]'>
                <img src={best3} alt="" className='mx-auto' />
                <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                  {t('award3')}
                </p>
              </div>
              <div className='blockcolor w-[269px] h-[327px]'>
                <img src={best4} alt="" className='mx-auto' />
                <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                  {t('award4')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#EDF2EF] flex justify-center relative xl:hidden h-[670px] mt-[120px] xl:mt-[160px]'>
        <img src={frameadapt} alt="" className='w-full block object-cover' />
        <div className='absolute left-0 flex justify-center items-center w-full h-full'>
          <div className='max-w-[300px] md:max-w-[600px] mx-auto'>
            <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] ml-[10px] xl:ml-0 mr-[10px] text-center font-medium'>
              {t('brand_built_title')}
            </h1>
            <p className='textcolor text-center roboto font-light text-[14px] md:text-[16px] mx-auto mt-[20px] ml-[10px] mr-[10px]'>
              {t('brand_built_description')}
            </p>
            <Swiper
              modules={[Navigation, Autoplay]}
              slidesPerView={1}
              centeredSlides={true}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              className='mt-[50px]'
              onInit={(e) => setSwiper(e)}
            >
              <SwiperSlide>
                <div className='blockcolor w-[269px] h-[327px] mx-auto'>
                  <img src={best1} alt="" className='mx-auto' />
                  <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                    {t('award1')}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='blockcolor w-[269px] h-[327px] mx-auto'>
                  <img src={best2} alt="" className='mx-auto' />
                  <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                    {t('award2')}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='blockcolor w-[269px] h-[327px] mx-auto'>
                  <img src={best3} alt="" className='mx-auto' />
                  <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                    {t('award3')}
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='blockcolor w-[269px] h-[327px] mx-auto'>
                  <img src={best4} alt="" className='mx-auto' />
                  <p className='w-[180px] textcolor manrope text-[16px] font-medium text-center mx-auto mt-[30px]'>
                    {t('award4')}
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='flex mx-auto w-[72px] mt-[30px]'>
              <img
                onClick={() => swiper.slidePrev()}
                src={button}
                alt=''
                className='w-[30px] h-[30px] rotate-180'
              />
              <img
                onClick={() => swiper.slideNext()}
                src={button}
                alt=''
                className='w-[30px] h-[30px] ml-[20px]'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandBuilt;
