import React from 'react';

import { useTranslation } from "react-i18next";

import logo from '../img/Vector.png'

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='w-[80px] xl:h-[45px] ml-[30px] xl:ml-0'/>
        <div className='ml-[30px] xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer1')} +441863440209</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer2')} 30 Douglas Road, Liverpool, Merseyside,<br/> United Kingdom, L4 2RQ</p>
          <p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer3')} support@opti-trade.com</p>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/Terms.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
          <a href="/Privacy.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
          <a href="/Opti.pdf"><p className='textcolor roboto text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[205px] pt-[40px]'>
        <p className='textcolor roboto text-[12px] font-normal'>{t('footer7')}</p>
        <p className='textcolor roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer8')}</p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] textcolor pr-[30px] xl:pr-0'>Opti Trade © 2023. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;