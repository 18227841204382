import React from 'react';

import { useTranslation } from "react-i18next";

import man from '../../img/Home/man.png';
import vector from '../../img/Home/vector.png';
import vector2 from '../../img/Home/vectoryellow.png';
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

function Main() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#DEE2E1] h-auto md:h-[980px] xl:h-[790px] flex'>
        <div className='mx-auto hidden xl:flex'>
            <div className='mr-[150px] mt-[100px] z-10'>
                <Fade left duration={500} delay={500}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_1')}</h1></Fade>
                <Fade left duration={500} delay={800}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_2')}</h1></Fade>
                <Fade left duration={500} delay={1100}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_3')}</h1></Fade>
            </div>
            <div>
                <Fade right duration={2000}><img src={man} alt="" className='relative z-10 mt-[50px] ml-[200px]'/></Fade>
                <Rotate duration={2000}><img src={vector} alt="" className='absolute top-[10px]'style={{ transformOrigin: 'center' }}/></Rotate>
                <Fade left duration={2000}><img src={vector2} alt="" className='absolute top-[60px]'/></Fade>
            </div>
            <div className='flex absolute top-[666px] z-10'>
                <Fade bottom duration={500} delay={500}><div className='bg-[#EDF2EF] rounded-[20px] h-[104px] w-[187px]'>
                    <p className='textmainblock text-center mt-[15px] text-[36px]'>$250</p>
                    <p className='roboto text-[16px] font- textcolor text-center'>{t('main_4')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={800}><div className='bg-[#EDF2EF] rounded-[20px] h-[104px] w-[187px] ml-[20px]'>
                    <p className='textmainblock text-center mt-[15px] text-[36px]'>1:200</p>
                    <p className='roboto text-[16px] font- textcolor text-center'>{t('main_5')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={1100}><div className='bg-[#EDF2EF] rounded-[20px] h-[104px] w-[187px] ml-[20px]'>
                    <p className='textmainblock text-center mt-[15px] text-[36px]'>0.0</p>
                    <p className='roboto text-[16px] font- textcolor text-center'>{t('main_6')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={1400}><div className='bg-[#EDF2EF] rounded-[20px] h-[104px] w-[187px] ml-[20px]'>
                    <p className='textmainblock text-center mt-[15px] text-[36px]'>65+</p>
                    <p className='roboto text-[16px] font- textcolor text-center'>{t('main_7')}</p>
                </div></Fade>
            </div>
        </div>
        <div className='flex flex-col xl:hidden mx-auto'>
            <div className='mt-[50px] ml-[20px] z-10'>
                <Fade left duration={500} delay={500}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_1')}</h1></Fade>
                <Fade left duration={500} delay={800}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_2')}</h1></Fade>
                <Fade left duration={500} delay={1100}><h1 className='text-[#493843] text-[40px] md:text-[48px] xl:text-[64px] font-semibold manrope'>{t('main_3')}</h1></Fade>
            </div>
            <div className='flex flex-wrap gap-[20px] mt-[39px] md:mt-[60px] justify-center z-10'>
                <Fade bottom duration={500} delay={500}><div className='bg-[#EDF2EF] rounded-[20px] h-[77px] w-[158px]'>
                    <p className='textmainblock text-center mt-[15px] text-[20px]'>$250</p>
                    <p className='roboto text-[12px] font- textcolor text-center'>{t('main_4')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={800}><div className='bg-[#EDF2EF] rounded-[20px] h-[77px] w-[158px]'>
                    <p className='textmainblock text-center mt-[15px] text-[20px]'>1:200</p>
                    <p className='roboto text-[12px] font- textcolor text-center'>{t('main_5')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={1100}><div className='bg-[#EDF2EF] rounded-[20px] h-[77px] w-[158px]'>
                    <p className='textmainblock text-center mt-[15px] text-[20px]'>0.0</p>
                    <p className='roboto text-[12px] font- textcolor text-center'>{t('main_6')}</p>
                </div></Fade>
                <Fade bottom duration={500} delay={1400}><div className='bg-[#EDF2EF] rounded-[20px] h-[77px] w-[158px]'>
                    <p className='textmainblock text-center mt-[15px] text-[20px]'>65+</p>
                    <p className='roboto text-[12px] font- textcolor text-center'>{t('main_7')}</p>
                </div></Fade>
            </div>
            <div>
                <Fade right duration={2000}><img src={man} alt="" className='relative z-10 w-[208.818px] h-[291.008px] md:w-[413.581px] md:h-[576.365px] mx-auto'/></Fade>
                <Rotate duration={2000}><img src={vector} alt="" className='absolute top-[400px] md:top-[200px]' style={{ transformOrigin: 'center' }}/></Rotate>
                <Fade left duration={2000}><img src={vector2} alt="" className='absolute top-[440px] md:top-[240px]'/></Fade>
            </div>
        </div>
    </div>
  );
}

export default Main;